/* Estilos del Banner */
.top-banner {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #3ca6a6; /* Fondo azul */
  color: white;
  text-align: center;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 50;
}

.banner-text {
  flex: 1;
  font-size: 14px;
  margin-left: 10px;
  padding: 0 20px;
}

.close-btn {
  background-color: white;
  color: #3ca6a6;
  font-weight: bold;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 30px;
  height: 35px;
  transition: background-color 0.3s ease;
}

.close-btn:hover {
  background-color: #e5e7eb;
}
