body {
  margin: 0;
  font-family: Arial, sans-serif;
  color: #333;
  text-align: center;
}
.App {
  margin: 0 auto;
}

.video-container {
  transition: background 0.1s ease-in;
}

.loading-screen {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-icon {
  height: 30vh;
}

.video-container {
  min-width: 100vw;
  width: 100vw;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #02737e;
}

.video-bg-active {
  background: white;
}

.swiper-pagination-bullet-active {
  background: #3ca6a6;
}

.swiper-pagination {
  text-align: right;
  padding-right: 30px !important;
}

.swiper-button-prev,
.swiper-button-next {
  color: white;
  backdrop-filter: blur(1px);
  border-radius: 50px;
  padding: 15px;
  height: 30px;
  margin-top: 5dvh;
  font-size: 10px;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  background-color: #3ca6a650;
}

.intro-video {
  height: 60%;
  object-fit: cover;
}

.logo {
  height: 25vmin;
  max-height: 150px;
  margin-right: 20px;
}

.App-header {
  background-color: #002a40;
  color: #f2f2f2;
  position: relative;
}

.nav-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 80px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 40px;
  z-index: 4;
}

.nav-btn {
  backdrop-filter: blur(5px);
  border: 2px #f2e3d5 solid;
  color: #f2e3d5;
  padding: 10px 20px;
  border-radius: 8px;
  text-decoration: none;
  transition: 0.3s;
}

.nav-btn:hover {
  background-color: #f2e3d5;
  color: #02737e;
}
.hero-bg {
  max-width: 450px;
  margin-left: 10vw;
  padding: 10px;
  backdrop-filter: blur(5px);
  border-radius: 50px;
}

.hero-section {
  max-width: 100%;
  min-width: 300px;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-size: cover;
  padding: 0px;
  background-position: 40%;
}

.features-section {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 5vw 10px 0vw 10px;
}

.feature-icon {
  width: 9vmin;
  padding: 20px;
}

.flex-cls {
  display: flex;
  gap: 10px;
  width: auto;
  flex-wrap: wrap;
  justify-content: center;
}
.link-cls {
  text-decoration: underline;
  color: white;
  font-weight: 700;
}
.feature-title {
  font-size: 1.5rem;
  margin: 10px 0;
  color: #f2e3d5;
}

.feature {
  max-width: 324px;
  background: #02495957;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  color: white;
}

.feature:hover {
  background-color: #3ca6a6af;
}

.hero-title {
  color: #f2e3d5;
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.hero-subtitle {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.hero-cta {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background-color: #026773;
  padding: 15px 30px;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
  gap: 10px;
  transition: 0.3s;
}

.hero-link {
  color: #f2e3d5 !important;
  text-decoration: none;
}

.hero-cta:hover {
  background-color: #3ca6a6d6;
}

.footer {
  display: absolute;
  left: 0;
  bottom: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #024959;
  color: white;
  padding: 20px;
}

.carousel-section {
  width: 95%;
  margin: 5vw auto 5vw auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-image {
  max-width: 800px;
  height: 400px;
  object-fit: cover;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  background-size: cover;
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #3ca6a6;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s ease-in-out;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scroll-to-top:hover {
  background-color: #3ca6a6;
}

@media (max-width: 768px) {
  .swiper-pagination {
    text-align: center;
    padding-right: 30px !important;
  }
  .feature-icon {
    width: 20vmin;
    padding: 20px;
  }
  .swiper-button-prev,
  .swiper-button-next {
    color: white;
    backdrop-filter: blur(1px);
    border-radius: 50px;
    padding: 5px;
  }

  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-size: 16px;
  }
  .hero-section {
    max-width: 100vw;
    height: 100dvh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: 65%;
  }
  .features-section {
    flex-direction: column;
    align-items: center;
    padding: 40px 20px 0vw 20px;
  }
  .intro-video {
    width: 100%;
    height: 40%;
    object-fit: cover;
  }
  .nav-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 40px);
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .hero-bg {
    max-width: 300px;
    width: 80vw;
    margin-left: 0vw;
    padding: 10px;
    border-radius: 50px;
    margin-bottom: -20vh;
  }
  .hero-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

  .hero-subtitle {
    font-size: 1rem;
    margin-bottom: 20px;
  }
  .carousel-container {
    width: 100%;
  }
  .carousel-image {
    width: auto;
    height: 30vh;
    background-size: cover !important;
  }

  .footer {
    color: #f2e3d5;
    padding: 20px;
    font-size: 12px;
    flex-direction: column;
  }
}
